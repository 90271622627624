import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  margin-bottom: 35px;
  font-family: "Fjalla One", sans-serif;
  h2 {
    color: #034f81;
    font-size: 3rem;
    margin: 20px;
  }
  .texts a:link,
    a:visited {
      color: #00adef;
    }
  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 3rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    .texts {
      position: absolute;
      top: 5%;
      left: 50%;
    }
  }

  @media only screen and (min-width: 1440px) {
    h2 {
      font-size: 5rem;
    }
    .texts {
      position: absolute;
      top: 5%;
      left: 50%;
    }
  }
  .mapsborder {
    border: 1px solid white;
  }
`;
