import React from "react";
import PropTypes from "prop-types";
import { Container } from "./hero.css";
import { Link } from "gatsby";
import Img from "gatsby-image";

const Hero = ({ img, text, buttonLien, buttonText }) => (
  <Container>
    <div>
      <Img fluid={img} alt="" />
      <div className="texts">
        <h2>
          {text} <Link to={buttonLien}>{buttonText}</Link>
        </h2>
      </div>
    </div>
  </Container>
);

Hero.propTypes = {
  img: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  buttonLien: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default Hero;
