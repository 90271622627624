import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Hero from 'components/hero';

export default function IndexPageTemplate({ data }) {
  const { markdownRemark, allMenuYaml } = data;
  const { frontmatter } = markdownRemark;
  return (
    <Layout
      pageTitle={frontmatter.title}
      lang={frontmatter.lang}
      menuLinks={allMenuYaml.nodes[0].menuItems}
    >
      <Hero
        img={frontmatter.image.childImageSharp.fluid}
        text={frontmatter.desc}
        buttonLien={frontmatter.button.link}
        buttonText={frontmatter.button.text}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!, $lang: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        desc
        lang
        button {
          link
          text
        }
        image {
          childImageSharp {
            fluid(maxHeight: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allMenuYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        menuItems {
          primaire {
            lien
            titre
          }
          produits {
            lien
            titre
          }
          services {
            lien
            titre
          }
          apropos {
            lien
            titre
          }
          accueil
          servicesTitre
          produitsTitre
          alert
        }
      }
    }
  }
`;
